.headerTitle {
    padding: 10px 0;
    text-align: center;
    background: #333;
    font-family: 'Josefin Sans', sans-serif;
    margin: 10px 20px 10px 10px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}
  .headerTitle img {
    width: 100px;
    height: 13px;
  }

  .headerTitle h4, h5,h6{
    font-family: 'Noto Serif', serif;
  }