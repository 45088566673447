@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500;1,600&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400;1,500&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap');
*, *::before, *::after{
  font-family: 'Montserrat', sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.InputContainer{
  padding: 0px 5px 0px 5px;
  
  font-family: 'Open Sans', sans-serif;
}

.MuiTableHead-root {
  position: sticky !important;
}

th {
  border-bottom: 1px solid #364043;
  color: #1e1e1e;
  background-color: #bdbdbd;
  font-size: 0.85em;
  font-weight: 600;
  padding: 0.5em 1em;
  text-align: left;
}
td {
  color: #1e1e12;
  font-weight: 400;
  padding: 0.65em 1em;
}
thead{
  background-color: #bdbdbd;
}

tbody tr {
  transition: background 0.25s ease;
}
tbody tr:hover {
  background: #ddd;
}

.rs-form-inline>*, .rs-form-inline>.rs-btn {
  margin-right: 16px;
  margin-bottom: 14px;
  vertical-align: top;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Card-Container{
  display:flex;
  flex-direction: row;
  flex:1;
  flex-wrap:wrap;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 15px;
}
.Cards{
   height:100px;
   width:250px;
   background:white;
   margin:15px;
   padding:10px;
   display:flex;
   border-style:solid;
   border-color:grey;
   border-width:0;
   box-shadow:2px 6px 12px 1px rgba(0,0,0,0.40);
   transition: transform .5s;
   border-radius:10px;
   -webkit-border-radius:10px;
   -moz-border-radius:10px;
   -ms-border-radius:10px;
   -o-border-radius:10px;
   -webkit-transition: transform .5s;
   -moz-transition: transform .5s;
   -ms-transition: transform .5s;
   -o-transition: transform .5s;
}
.Cards:hover{
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
}
  to {
    transform: rotate(360deg);
  }
}
